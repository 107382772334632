import React, { Component } from 'react';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TableBody, Chip } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button, FormControl, Card } from 'react-bootstrap';
import { PostData, GetData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const ctypes = [
    {
        label: 'Facilities',
        value: 'COLLEGE_FACILITIES'
    },
    {
        label: 'Extension',
        value: 'COLLEGE_EXTENSIONS'
    },
    {
        label: 'Supportss',
        value: 'COLLEGE_SUPPORTS'
    },
    {
        label: 'Achievements',
        value: 'COLLEGE_ACHIEVEMENTS'
    },
    {
        label: 'Research & Consultancy',
        value: 'COLLEGE_RESEARCH'
    },
    {
        label: 'Publication',
        value: 'COLLEGE_PUBLICATION'
    },
    {
        label: 'Innovative Practices',
        value: 'COLLEGE_INNOVATIVE'
    },
    {
        label: 'Focus',
        value: 'COLLEGE_FOCUS'
    },
    {
        label: 'Online Programmes',
        value: 'COLLEGE_ONLINE'
    },
    {
        label: 'Online :: Miscellaneous',
        value: 'COLLEGE_ONLINE_MIS'
    },
    {
        label: 'IQAC AQAR',
        value: 'IQAC_AQAR'
    },
    {
        label: 'IQAC Minutes',
        value: 'IQAC_MINUTES'
    },
    {
        label: 'IQAC Action Taken',
        value: 'IQAC_ACTION_TAKEN'
    },
    {
        label: 'Important Files',
        value: 'IMPORTANT_FILES'
    }
]


class CollegeOthers extends Component {
    state = {
        id: '',
        edit: false,
        description: '',
        type: '',
        title: '',
        upload_info: [],
        data: [],
        isDataLoaded: false,
        dept_code: this.props.dept_code,
        message: '',
        open: false,
        loadFile: true
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    __getDeptData = () => {
        GetData(`/${this.props.dept_code}/NONE/getdepartmentfilebytype`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isDataLoaded: true
                })
            })
    }

    componentDidMount() {
        this.__getDeptData();

    }


    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            id: this.state.id,
            title: this.state.title,
            description: this.state.description,
            upload_info: this.state.upload_info,
            type: this.state.type,
            dept_code: this.props.dept_code
        }

        if (this.state.edit) {
            PutData(`/admin/${this.state.id}/editdepartmentfile`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        } else {
            PostData(`/admin/adddepartmentfile`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        }


    }

    onDeleteClick = (el) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, el.id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm = (id) => {
        DeleteData(`/${this.props.apikey}/${id}/deletedepartmentfile`)
            .then((resp) => {
                this.setState({
                    message: resp,
                    open: true
                }, () => this.__getDeptData())
            })
    }

    onEditClick = (el) => {
        this.setState({
            ...el,
            edit: true,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }

    cancelEdit = () => {
        this.setState({
            id: '',
            title: '',
            description: '',
            upload_info: [],
            edit: false,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Type wise file upload
                    </Typography>


                    <Card>

                        <Card.Body>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <label>Type</label>
                                    <FormControl
                                        as="select"
                                        name="type"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.type}
                                        placeholder="Title"
                                    >
                                        <option value="">Select</option>
                                        {ctypes.map((el,index) => 
                                                <option key={index} value={el.value}>{el.label}</option>
                                            )}
                                    </FormControl>


                                    <label>Title</label>
                                    <FormControl
                                        as="input"
                                        name="title"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.title}
                                        placeholder="Title"
                                    />

                                    <label>Description</label>
                                    <ReactQuill
                                        placeholder="Type something here"
                                        value={this.state.description}
                                        onChange={this.handleChange.bind(this, 'description')}
                                    />
                                </Grid>
                                {this.state.type && <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <label>Upload photo or file</label>
                                    {this.state.loadFile ? (<FileUpload
                                        dp={this.state.upload_info}
                                        type={`COLLEGE_OTHERS`}
                                        setDp={this.handleChange.bind(this, 'upload_info')}
                                    />) : null}
                                </Grid>}
                            </Grid>



                            {this.state.edit ? (
                                <div align="right">
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="button"
                                        onClick={this.cancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : (<div align="right">
                                <Button
                                    size="sm"
                                    variant="info"
                                    type="submit"
                                >
                                    Submit
                    </Button>
                            </div>)}

                        </Card.Body>

                    </Card>

                    <br />


                    <Card>

                        <Card.Body>
                            {this.state.isDataLoaded ? (
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell width="30%">Type</TableCell>
                                                <TableCell width="30%">Title</TableCell>
                                                <TableCell width="30%">Description</TableCell>
                                                <TableCell wodth='20%'>Files</TableCell>
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.data.map((el, index) =>

                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{el.type}</TableCell>
                                                    <TableCell>{el.title}</TableCell>
                                                    <TableCell dangerouslySetInnerHTML={{ __html: el.description }}></TableCell>
                                                    <TableCell>
                                                        {Array.isArray(el.upload_info) && el.upload_info.map((el1, index1) =>
                                                            <Chip
                                                                key={index1}
                                                                label={el1.path}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell><Edit onClick={this.onEditClick.bind(this, el)} /></TableCell>
                                                    <TableCell><Delete onClick={this.onDeleteClick.bind(this, el)} /></TableCell>
                                                </TableRow>

                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            ) : <Typography>
                                    Loading . . .
                            </Typography>}
                        </Card.Body>

                    </Card>

                </form>
            </div>
        );
    }
}


export default CollegeOthers;