import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, Typography, CardContent, Grid, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem, CardActions, Button } from '@material-ui/core';
import Add from '@material-ui/icons/Add'
import Rich from './Rich';
import FileUpload from './FileUpload';
import { PostData } from '../../api/service';
import Snack from '../Snackbar/Snack';

const styles = theme => ({
  textField: {
    width: '100%'
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing.unit * 2
  }
})

class AddNewContainer extends Component {

  state = {
    date: '',
    type: this.props.code,
    posted_as: '',
    type: '',
    title: '',
    description: '',
    upload_info: [],
    opening_date: '',
    dept_code: 'TNC',
    closing_date: '',
    open: false,
    message: ''
  }

  __getInitialState() {
    this.setState({
      date: '',
      type: this.props.code,
      posted_as: '',
      type: '',
      title: '',
      description: '',
      upload_info: [],
      opening_date: '',
      dept_code: 'TNC',
      closing_date: '',
    })
  }

  __loadType() {
    this.setState({
      type: this.props.code
    })
  }

  componentDidMount() {
    this.__loadType();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.__loadType()
    }
  }



  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleRichChange = (data) => {
    this.setState({
      description: data
    })
  }

  onSetFile = (data) => {
    //console.log(data)
    this.setState({
      uploadInfo: data
    })
  }

  onSubmit = (e) => {
    e.preventDefault();

    let data = {
      ...this.state
    }

    //console.log(data);


    PostData(`/${this.props.apikey}/createnotice`, data)
      .then((resp) => {
        //console.log(resp)
        this.setState({
          open: true,
          message: resp
        })
      }).then(() => {
        this.__getInitialState();
      })

  }

  render() {
    let { classes } = this.props;

    let i = <Grid key={0} item lg={4} sm={12}>
      <TextField
        label="Opening Date"
        className={classes.textField}
        type="date"
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}

        name="opening_date"
        value={this.state.opening_date}
        onChange={this.handleChange}
      />
    </Grid>;

    let j = <Grid key={1} item lg={4} sm={12}>
      <TextField
        label="Closing Date"
        className={classes.textField}
        type="date"
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}

        name="closing_date"
        value={this.state.closing_date}
        onChange={this.handleChange}
      />
    </Grid>
    return (
      <div>

        {this.state.open ?
          <Snack
            open={this.state.open}
            handleClose={() => {
              this.setState({
                open: false,
                message: ''
              })
            }}
            message={this.state.message}
          />

          : null}
        <form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Add />
                </Avatar>
              }
              title={
                <Typography
                  variant="h6"
                >
                  Add New {this.props.title}
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={4} sm={12}>
                  <TextField
                    label="Date"
                    className={classes.textField}
                    type="date"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required={true}
                    name="date"
                    value={this.state.date}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item lg={4} sm={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel >
                      Type
                    </InputLabel>
                    <Select
                      name="type"
                      value={this.state.type}
                      onChange={this.handleChange}
                      input={
                        <OutlinedInput labelWidth={200} name="type" />
                      }
                      required={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="COLLEGE_TENDER">College Tender</MenuItem>
                      <MenuItem value="COLLEGE_ADMIN_NOTICE">Administrative Notice</MenuItem>
                      <MenuItem value="COLLEGE_ACADEMIC_NOTICE">Academic Notice</MenuItem>
                      <MenuItem value="COLLEGE_EVENT">News</MenuItem>
                      <MenuItem value="COLLEGE_DOWNLOADS">Downloads</MenuItem>
                      <MenuItem value="COLLEGE_ACHIEVEMENTS">College Achievements</MenuItem>
                      <MenuItem value="COLLEGE_EMP_ACHIEVEMENTS">Employee Achievements</MenuItem>
                      <MenuItem value="COLLEGE_STU_ACHIEVEMENTS">Student Achievements</MenuItem>
                      <MenuItem value="COLLEGE_PUBLICATIONS">College Publications</MenuItem>
                      <MenuItem value="COLLEGE_DEPT_PUBLICATIONS">Departmental Achievements</MenuItem>
                      <MenuItem value="COLLEGE_TEACHER_PUBLICATIONS">Teacher Achievements</MenuItem>
                      <MenuItem value="COLLEGE_STUDENT_PUBLICATIONS">Student Achievements</MenuItem>
                      <MenuItem value="NIRF">NIRF DATA</MenuItem>
                      <MenuItem value="CLASS_ROUTINES">Class Routine</MenuItem>
                      <MenuItem value="EXAM_RESULTS">Exam Results</MenuItem>
                      <MenuItem value="EXAM_SCHEDULE">Exam Schedule</MenuItem>
                      <MenuItem value="STUDENT_CLUBS">Student Clubs</MenuItem>
                      
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={4} sm={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel >
                      Post As
                    </InputLabel>
                    <Select
                      value={this.state.posted_as}
                      onChange={this.handleChange}
                      input={
                        <OutlinedInput labelWidth={200} name="posted_as" />
                      }
                      required={true}
                      name="posted_as"

                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Thong Nokbe College">Thong Nokbe College</MenuItem>
                      <MenuItem value="IQAC Coordinator">IQAC Coordinator</MenuItem>
                      <MenuItem value="Principal">Principal</MenuItem>
                      <MenuItem value="Student's Union">Student's Union</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {(this.state.type === 'COLLEGE_TENDER' || this.state.type === 'events') ? [i, j] : null}

                <Grid item lg={12} sm={12}>
                  <TextField
                    label="Title"
                    className={classes.textField}
                    type="text"
                    margin="normal"
                    variant="outlined"
                    required={true}
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item lg={12} sm={12}>
                  <Rich
                    placeholder="Description....."
                    onChange={this.handleRichChange}
                  />
                </Grid>

                <Grid item lg={12} sm={12}>
                  {this.props.code !== "" && <FileUpload
                    dp={this.state.upload_info}
                    apikey={this.props.apikey}
                    type={this.props.code}
                    setDp={this.onSetFile.bind(this)}
                  />}
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
              >
                Submit
            </Button>
            </CardActions>

          </Card>

        </form>

      </div>
    )
  }
}

export default withStyles(styles)(AddNewContainer)