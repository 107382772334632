import React, { Component } from 'react'
import { withStyles, Button, Grid, TextField, Chip, Typography } from '@material-ui/core';
import { PostFormData, DeleteData } from '../../api/service';
import CloudUpload from '@material-ui/icons/CloudUpload'
import Delete from '@material-ui/icons/Delete'
import { blue } from '@material-ui/core/colors';



const styles = theme => ({
    textField: {
        //opacity: 0,
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        color: 'white'
    },
    button: {
        marginTop: '13px'
    },
    uploadButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '20vh',
        backgroundColor: '#3F51B5',
        color: 'white',
        position: 'relative'
    },
    uploadIcon: {
        fontSize: 50
    },
    uploadText: {
        color: 'white',
        fontWeight: 300,
    },
    uploadedItem: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        border: 'solid thin rgba(0,0,0,0.3)',


    },
    uploadItemLeft: {
        flexBasis: '10%',
        textAlign: 'center',
        backgroundColor: 'rgba(0,0,0,0.1)',
        padding: theme.spacing.unit
    },
    uploadItemMiddle: {
        flexBasis: '80%',
        padding: theme.spacing.unit
    },
    uploadItemRight: {
        flexBasis: '10%',
        padding: theme.spacing.unit
    }
})

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: '',
            filename: '',
            file: '',
            errors: {},
            dp: [],
            apikey: '',
            loading: false
        }

        this.onChange = this.onChange.bind(this);
        this.addFile = this.addFile.bind(this);
        this.fileHandle = this.fileHandle.bind(this);


    }
    __initialise() {
        this.setState({
            type: this.props.type,
            filename: '',
            file: '',
            errors: {}
        })
    }

    componentDidMount() {


        this.__initialise();

        let user = JSON.parse(localStorage.getItem('user'));
        let apikey = user.user.apikey;


        this.setState({
            dp: this.props.dp,
            apikey: apikey
        })

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    fileHandle(e) {
        
        if (this.props.type !== "" && this.props.type != null && this.props.type != undefined) {
            this.setState({
                filename: e.target.value,
                file: e.target.files[0]
            }, () => {
                this.addFile();
            })
        }


    }

    handleValidation() {
        let { type, filename } = this.state;
        let isValid = true;

        let errors = {}

        if (type !== '') {
            isValid = true;
            errors['type'] = "";
        } else {
            isValid = false;
            errors['type'] = "Cannot be empty";
        }

        if (filename !== '') {
            isValid = true;
            errors['filename'] = "";
        } else {
            isValid = false;
            errors['filename'] = "Cannot be empty";
        }


        this.setState({
            errors
        })

        return isValid;


    }

    addFile() {
        if (this.handleValidation) {

            this.setState({
                loading: true
            })
            let formdata = new FormData();
            formdata.append('type', this.props.type);
            formdata.append('image_file', this.state.file);

            PostFormData(`/${this.state.apikey}/addfile`, formdata)
                .then((resp) => {
                    //console.log(resp);
                    this.setState({
                        loading: false
                    })
                    if (resp !== false) {
                        if (resp.type === this.props.type) {
                            let dp = this.state.dp;
                            if(dp == null){
                                dp = []
                            }
                            dp.push(resp);
                            this.props.setDp(dp);
                            this.setState({
                                dp: dp
                            })
                        }
                    }

                    this.__initialise();
                })
        }
    }



    onDpDelete(el, index) {
        let dp = this.state.dp;
        dp.splice(index, 1);
        DeleteData(`/${this.state.apikey}/${el.type}/${el.path}/deletefile`)
            .then((resp) => {
                if (resp === true) {
                    this.props.setDp(dp);
                    this.setState({
                        dp
                    })
                }
            })

    }




    render() {
        let { classes } = this.props;
        let d = [];
        let dp = this.state.dp;
        if (Array.isArray(dp) && dp.length > 0) {
            //console.log(dp)
            d = dp.map((el, index) => {

                if(el.mime != 'link'){
                    return( <UploadedItemDiv
                        {...this.props}
                        key={index}
                        index={index + 1}
                        fileName={el.path}
                        onDelete={this.onDpDelete.bind(this, el, index)}
                    />)
                }
                   
            }
                
            )

        }
        return (
            <div>
                
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <div
                            className={classes.uploadButton}
                        >

                            <CloudUpload className={classes.uploadIcon} />
                            {this.state.loading ? <Typography
                                className={classes.uploadText}
                                style={{color: 'red !important'}}
                            >
                                Uploading. . . . Please wait.
                            </Typography> :  <Typography
                                className={classes.uploadText}
                            >
                                Click here to upload (Size less than 5 MB)
                            </Typography>}
                            <TextField
                                name="filename"
                                type="file"
                                margin="normal"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={this.state.filename}
                                onChange={this.fileHandle}
                            />
                        </div>

                    </Grid>
                    {/* <Grid item xs={12} lg={12}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={this.addFile}
                        >
                            Upload
                        </Button>
                    </Grid> */}

                    <Grid item xs={12} lg={6}>

                        {d}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(FileUpload);

const UploadedItemDiv = (props) => {
    let { classes } = props;
    return (
        <div className={classes.uploadedItem}>
            <div className={classes.uploadItemLeft}>
                {props.index}
            </div>
            <div className={classes.uploadItemMiddle}>
                {props.fileName}
            </div>

            <div className={classes.uploadItemRight}>
                <Delete onClick={props.onDelete} />
            </div>
        </div>
    )
}